import React from 'react';
import IconBoxStyle11 from '../../IconBox/IconBoxStyle11';
import Spacing from '../../Spacing';

export default function ContactInfoSection({ sectionTitle }) {
  return (
    <div className="container">
      <h2 className="cs_fs_72 mb-0">{sectionTitle}</h2>
      <Spacing md="70" lg="50" />
      <div className="row g-4 g-xl-3 g-xxl-5">
        <div className="col-xl-4">
          <IconBoxStyle11
            title="WhatsApp İletişim"
            subTitle="+90 534 019 82 29"
            subTitle2="+48 517 326 010"
            iconSrc="/images/contact/icon_1.svg"
          />
        </div>
        <div className="col-xl-4">
          <IconBoxStyle11
            title="PayPal & Email"
            subTitle="elemanoglu@gmail.com"
            iconSrc="/images/contact/icon_2.svg"
          />
        </div>
        <div className="col-xl-4">
          <IconBoxStyle11
            title="Address"
            subTitle="İnkılap Mah. Toros Sk. No:1/2 34775 Ümraniye/İstanbul, Türkiye"
            iconSrc="/images/contact/icon_3.svg"
          />
        </div>
      </div>
      
    </div>
  );
}
