import React from 'react';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';

export default function Hero(
  
  {
  title,
  subTitle,
  bgUrl,
  imgUrl,
  banneBtnTitle,
  videoUrl,
  infoList,
  btnText,
  btnUrl,
}) {
 
  
  return (
  
    <section className="cs_hero cs_style_1">
      <div
        className="cs_hero_wrap cs_bg_filed"
        style={{ backgroundImage: `url(${bgUrl})` }}
      >
        <div className="container">
          <div className="cs_hero_text">
            <h1 className="cs_hero_title cs_fs_94">{parse(title)}</h1>
            <p className="cs_hero_subtitle cs_fs_20 cs_heading_color">
              {parse(subTitle)} 
            </p>
            <div className="cs_hero_btn_wrap">
              
            <Link target='blank' to={"https://docs.google.com/forms/d/10yHvmpWB3q7n8HfQ47Ep1-nwF7Ws-umSWbbNj0kq3kU/edit"}  className="bannerbtn btn btn-dark ps-4 pe-4 pt-3 pb-3" style={{ backgroundColor: '#164353' }}>Forma Gidin</Link>


            </div>
          </div>
          <img src={imgUrl} alt="Hero" className="cs_hero_img" />
          <div className="cs_hero_info_wrap cs_shadow_1 cs_white_bg cs_radius_15">
            {infoList.map((item, index) => (
              <div className="cs_hero_info_col" key={index}>
                <div className="cs_hero_info d-flex align-items-center">
                <div 
  className="cs_hero_info_icon cs_center rounded-circle cs_accent_bg"
  style={{ 
      backgroundColor: '#25D366', 
      
  }}
>
  <img src={item.iconUrl} alt="Icon" />
</div>
                  <div className="cs_hero_info_right">
                    <h3 className="cs_hero_info_title cs_semibold">
                      {item.title}
                    </h3>
                     {/* WhatsApp Link */}
                <a href={`https://wa.me/${item.phone}/?text=Merhaba`} target="_blank" rel="noopener noreferrer">
                  <p className="cs_hero_info_subtitle cs_fs_20">
                    {item.subTitle} <svg xmlns="http://www.w3.org/2000/svg" width="1.2rem" height="1.2rem" viewBox="0 0 256 256"><path fill="currentColor" d="m224.15 179.17l-46.82-46.82l37.92-13.51c.26-.09.51-.19.76-.3a20 20 0 0 0-1.76-37.27L54.16 29A20 20 0 0 0 29 54.16l52.27 160.08a20 20 0 0 0 37.27 1.76c.11-.25.21-.5.3-.76l13.51-37.92l46.83 46.82a20 20 0 0 0 28.28 0l16.69-16.68a20 20 0 0 0 0-28.29m-30.83 25.17l-48.48-48.48A20 20 0 0 0 130.7 150a20.5 20.5 0 0 0-3.73.35A20 20 0 0 0 112.35 162c-.11.25-.2.5-.3.76L100.4 195.5L54.29 54.29L195.5 100.4l-32.71 11.65c-.25.09-.51.19-.76.3a20 20 0 0 0-6.16 32.48l48.48 48.48ZM84 16v-4a12 12 0 0 1 24 0v4a12 12 0 0 1-24 0m-72 92a12 12 0 0 1 0-24h4a12 12 0 0 1 0 24Zm108.62-83.79l4-12a12 12 0 0 1 22.77 7.58l-4 12a12 12 0 0 1-22.77-7.58m-81.23 104a12 12 0 0 1-7.59 15.17l-12 4a12 12 0 1 1-7.59-22.76l12-4a12 12 0 0 1 15.18 7.59"/></svg>
                  </p>
                </a>
                  </div>
                </div>
              </div>
            ))}
            <div className="cs_hero_info_col">
              {/* <Link to={btnUrl} className="cs_btn cs_style_1">
                <span>{btnText}</span>
                <i>
                  <img src="/images/icons/arrow_white.svg" alt="Icon" />
                  <img src="/images/icons/arrow_white.svg" alt="Icon" />
                </i>
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
