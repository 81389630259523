import React from 'react';
import { Link } from 'react-router-dom';

export default function FooterStyle2() {
  const currentYear = new Date().getFullYear(); 

  return (
    <footer className="cs_footer cs_style_2 cs_accent_bg cs_white_color">
      
      <div className="container">
        
      </div>
      <div className="cs_footer_bottom">
        <div className="container">
          <div className="cs_copyright">
            Copyright © {currentYear} onlineturkdoktor.com tüm hakları saklıdır.
            <br />
            designed by <Link className='creamycode' target='blank'  to={"https://creamycode.com"}>creamycode.com</Link>
          </div>
        </div>
      </div>
    </footer>
  );
}
