import React, { useState, useRef, useEffect } from "react";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const ContactForm = () => {
  const formRef = useRef();
  const [formData, setFormData] = useState({
    user_name: "",
    user_email: "",
    user_subject: "",
    user_message: "",
    user_number: ""
  });
  const [errors, setErrors] = useState({});
  const [mathQuestion, setMathQuestion] = useState({});
  const [userAnswer, setUserAnswer] = useState("");

  // Generate a simple math question
  const generateMathQuestion = () => {
    const num1 = Math.floor(Math.random() * 10) + 1;
    const num2 = Math.floor(Math.random() * 10) + 1;
    setMathQuestion({ num1, num2, answer: num1 + num2 });
  };

  // Initialize the math question when the component mounts
  useEffect(() => {
    generateMathQuestion();
  }, []);

  const validateForm = () => {
    let formErrors = {};
    if (!formData.user_name) formErrors.user_name = "Name is required";
    if (!formData.user_email) {
      formErrors.user_email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.user_email)) {
      formErrors.user_email = "Email address is invalid";
    }
    if (!formData.user_message) formErrors.user_message = "Message is required";
    if (parseInt(userAnswer) !== mathQuestion.answer) {
      formErrors.math = "Incorrect answer to math question";
    }
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleInputChange = (e) => {
    if (e.target.name === "userAnswer") {
      setUserAnswer(e.target.value);
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      const result = await emailjs.sendForm(
        "service_n4il6oq",
        "template_fuyeszj",
        formRef.current,
        "LllbJlzMszOm8AO6G"
      );
      MySwal.fire({
        title: "Success!",
        text: "Your message has been sent!",
        icon: "success",
        confirmButtonText: "OK"
      });
      setFormData({ user_name: "", user_email: "", user_subject: "", user_message: "", user_number: "" });
      setUserAnswer("");
      generateMathQuestion(); // Regenerate a new math question
      setErrors({}); // Clear any errors
    } catch (error) {
      MySwal.fire({
        title: "Error!",
        text: "Failed to send message. Please try again later.",
        icon: "error",
        confirmButtonText: "OK"
      });
    }
  };

  return (
    <div className="cs_contact_form cs_style_1 cs_white_bg cs_radius_30 mt-5">
      <form ref={formRef} onSubmit={handleSubmit}>
        <div className="row" id="contactform-id-scroll">
          <div className="col-lg-6">
            <label className="cs_input_label cs_heading_color">İsim Soyisim</label>
            <input
              type="text"
              className="cs_form_field"
              placeholder="Ahmet Neşeli"
              name="user_name"
              value={formData.user_name}
              onChange={handleInputChange}
            />
            {errors.user_name && <p style={{ color: 'red' }}>{errors.user_name}</p>}
          </div>
          <div className="col-lg-6">
            <label className="cs_input_label cs_heading_color">Email</label>
            <input
              type="email"
              className="cs_form_field"
              placeholder="example@gmail.com"
              name="user_email"
              value={formData.user_email}
              onChange={handleInputChange}
            />
            {errors.user_email && <p style={{ color: 'red' }}>{errors.user_email}</p>}
          </div>
          <div className="col-lg-6 mt-3">
            <label className="cs_input_label cs_heading_color">Rahatsızlığınız</label>
            <input
              type="text"
              className="cs_form_field"
              placeholder="Öksürük ve yüksek ateş"
              name="user_subject"
              value={formData.user_subject}
              onChange={handleInputChange}
            />
          </div>
          <div className="col-lg-6 mt-3">
            <label className="cs_input_label cs_heading_color">Telefon Numaranız</label>
            <input
              type="text"
              className="cs_form_field"
              placeholder="123 456 789"
              name="user_number"
              value={formData.user_number}
              onChange={handleInputChange}
            />
          </div>
          <div className="col-lg-12 mt-3">
            <label className="cs_input_label cs_heading_color">Mesajınız</label>
            <textarea
              cols={30}
              rows={10}
              className="cs_form_field"
              placeholder="Merhaba, öksürük ve yüksek ateş rahatsızlığım var."
              name="user_message"
              value={formData.user_message}
              onChange={handleInputChange}
            />
            {errors.user_message && <p style={{ color: 'red' }}>{errors.user_message}</p>}
          </div>
          <div className="col-lg-12 mt-3">
            <label className="cs_input_label cs_heading_color">
              Cevap? {mathQuestion.num1} + {mathQuestion.num2} =
            </label>
            <input
              type="number"
              className="cs_form_field"
              placeholder="Your answer"
              name="userAnswer"
              value={userAnswer}
              onChange={handleInputChange}
              required
            />
            {errors.math && <p style={{ color: 'red' }}>{errors.math}</p>}
          </div>
          <div className="col-lg-12 mt-5">
            <button type="submit" className="cs_btn cs_style_1">
              <span>Gönder</span>
              <i><img src="/images/icons/arrow_white.svg" alt="Icon" /></i>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
