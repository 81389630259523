import { Route, Routes, useLocation } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './components/Pages/Home';

import { useEffect } from 'react';
import ErrorPage from './components/Pages/ErrorPage';


function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        
      </Route>
    
      
      
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
}

export default App;
